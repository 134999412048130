import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout >
    <section className="section" style={{minHeight: "78vh"}}>
      <div className="container">
        <div className="content">
          <h1>Teşekkürler</h1>
          <p>Online randevu talebiniz alınmıştır.</p>
        </div>
      </div>
    </section>
  </Layout>
);
